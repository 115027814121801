<template>
  <div>
    <!-- SECTION 1 -->
    <section class="section">
      <CContainer class="">
        <CRow>
          <CCol sm="12" class="text-center block-header">
            <h2>{{ article.title }}</h2>
          </CCol>
          <CCol sm="12">
            <CImg
              v-if="article.image"
              :src="storageURL + article.image"
              width="400"
              block
              class="mb-2 article-img"
            />
            <CImg
              v-else
              src="/img/frontpage/image-placeholder.jpg"
              width="400"
              block
              class="mb-2 article-img"
            />
          </CCol>

          <CCol sm="12" class="project-detail-block">
            <div v-html="article.content"></div>
          </CCol>
        </CRow>
      </CContainer>
    </section>
  </div>
</template>

<script>
import ArticleAPI from "/app/src/api/article.js";

export default {
  name: "ArticleDetail",
  data: () => {
    return {
      articleAPI: new ArticleAPI(),
      article: {},
      storageURL: process.env.VUE_APP_STORAGE_URL,
    };
  },

  created: function () {
    this.articleAPI
      .detail(this.$route.params.id)
      .then((article) => {
        this.article = article;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {},
};
</script>

<style scoped>
.article-img {
  margin: 0 auto;
  border-radius: 15px;
}
</style>
